import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import SiteNav from '../components/SiteNav'
import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'

class LiveStream extends React.Component {
  render() {

    return (
      <Layout full>
        <Helmet
          title="MB productions - live streaming"
          meta="We do live streaming from time to time. Tune in here for some quality tunes."
        />
        <SiteNav sticky />

        <HeaderGeneric title="live streaming"><p>We like to hang out at <a href="https://twitch.tv/mixbprod">twitch.tv/mixbprod</a></p></HeaderGeneric>

        <main id="main" className="stream">
          <section id="content" className="stream-column stream-column__stream">
            <iframe id="streamFrame" title="stream"
              src="https://player.twitch.tv/?channel=mixbprod&parent=www.mixbprod.com&muted=false"
              width="100%"
              frameBorder="0"
              scrolling="no"
              allowfullscreen="true">
            </iframe>
          </section>
          <section id="chat" className="stream-column stream-column__chat">
            <iframe title="chat" frameBorder="0"
              scrolling="no"
              id="chat_embed"
              src="https://www.twitch.tv/embed/mixbprod/chat?parent=www.mixbprod.com"
              className="chat"
              style={{ "border": "1px solid #eee" }}
              width="100%">
            </iframe>
          </section>
          <section id="content">
            <p></p>
            <h2>
              Next live event:
            </h2>
            <p>
              <b>Sunday 26th April 2020 @ 1pm (AEST)</b>
              <br /><i>(Saturday night 25th April @ 10pm US CDT)</i>
              <br /><i>(Sunday early morning 26th April @ 3am UTC)</i>
            </p>
            <ul className="actions">
              <li>
                <Link className="button" to="/">Back to home page</Link>
              </li>
            </ul>
          </section>
        </main>
        <section>
        </section>
      </Layout>
    )
  }


}

export default LiveStream
