import React from 'react'

const HeaderGeneric = ({ title, children }) => (
    <header id="header" className="header">
        <h1>{title}</h1>
        {children}
    </header>
)

export default HeaderGeneric
