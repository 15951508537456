import { Link } from 'gatsby'
import React from 'react'
import logo from '../assets/images/logo_only_large_trans.svg';

const SiteNav = () => (
    <nav id="site-nav">
        <Link to="/">
            <img className="logo" src={logo} alt="" />
            <div className="caption">MB<span className="caption-home"> home</span></div>
        </Link>
    </nav>
)

export default SiteNav
